import React from "react";
import { useIntl } from "gatsby-plugin-react-intl";
import { RedirectTo } from "../shared/RedirectTo";
import { ThankyouPage } from "../components/ThanyouPage";

export default function Obrigado() {
  const intl = useIntl();

  if (intl.locale === "en-us") {
    return RedirectTo({ href: `/thank-you` });
  }

  if (intl.locale === "es-es") {
    return RedirectTo({ href: `/gracias` });
  }

  return <ThankyouPage />;
}
